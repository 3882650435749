var isPage = "";
let lang = "ja";
let Bg = {
	top: 0,
	show: 0,
	btm: 0,
	id: ""
}
let bgs = [];

let Block = {
	top: 0,
	show: 0,
	btm: 0,
	id: ""
}
let blocks = [];

let Animate = {
	top: 0,
	show: 0
}
let animations = [];
const common = {
	beforeW: 0,
	currentBG: 0,
	init: ()=>{
		var resizeFlag;
		var resizeTimer = null;
		var $body = $("body");

		common.resize();
		common.nav();

		setTimeout(function(){
			$("body").removeClass("disable");
		}, 600);
		/* window resize
		----------------------------- */
		var resizeCallback = function() {
			common.resize();
			resizeTimer = null;
		};
		common.scrl();
		$(document).on("scroll touchmove", function(){
			common.scrl();
		});

		$(window).off("resize").on("resize",function(){
			if(!$body.hasClass("resize")){
				$body.addClass("resize");
			}
			if(resizeTimer) {
				clearTimeout(resizeTimer);
			}
			resizeTimer = setTimeout(resizeCallback, 300);
		});
	},
	nav: ()=>{
		$("h1 a").off().on("click", function(){
			common.scrollToPosition(0,1000);
		});

		$("#glbnav li a").off().on("click", function(){
			let idx = $("#glbnav li a").index(this);
			let posY = blocks[idx].top;
			common.scrollToPosition(posY, 1000);
		});
		$("a#btn_menu").off().on("click", function(){
			if( !$html.classList.contains("menu") ){
				$html.classList.add("menu");
				setTimeout(function(){
					$html.classList.add("show_menu");
				}, 300);
			}else{
				$html.classList.remove("show_menu");
				setTimeout(function(){
					$html.classList.remove("menu");
				}, 200);
			}
		});

		$(".page_nav li a").off().on("click", function(){
			let idx = $(".page_nav li a").index(this);
			let posY = blocks[idx].top;
			common.scrollToPosition(posY, 1000);
			$html.classList.remove("show_menu");
			setTimeout(function(){
				$html.classList.remove("menu");
			}, 200);
		});
	},
	resize: ()=>{
		var winW = window.innerWidth;
		var winH = window.innerHeight;
		var $body = $("body");
		if (window.matchMedia('all and (min-width : 864px)').matches) {
			isSmallScreen = false;
		} else if(window.matchMedia('all and (min-width : 0px)').matches) {
			isSmallScreen = true;
		}
		if(isSmallScreen){
			if(common.beforeW != winW){
				$("#main").css({
					"height": winH + "px"
				});
			}
			if(isIPad){
				if(common.beforeW != winW){
					$("#main, .full").css({
						"height": ""
					});
					$(".full").each(function(){
						$(this).css({
							"height": ""
						});
					});
				}
			}
		}else{
			let _h = winH;
			let minH = 680;
			if(_h < minH){
				_h = minH;
			}
			if(isIPad){
				if(common.beforeW != winW){
					$("#main, .full").css({
						"height": _h + "px"
					});
					$(".full").each(function(){
						$(this).css({
							"height": _h + "px"
						});
					});
				}
			}else{
				$("#main, .full").css({
					"height": _h + "px"
				});
				$(".full").each(function(){
					$(this).css({
						"height": _h + "px"
					});
				});
			}
		}
		bgs 		= [];
		blocks 		= [];
		animations 	= [];
		$('[data-bg]').each(function(i){
			let _b = object(Bg);
			_b.top = Math.floor( $(this).offset().top );
			_b.btm = Math.floor( _b.top + window.innerHeight );
			_b.show = Math.floor( _b.top + window.innerHeight/3 );
			bgs.push(_b);
		});
		$(".block").each(function(i){
			let _b = object(Bg);
			_b.top = Math.floor( $(this).offset().top );
			_b.btm = Math.floor( _b.top + window.innerHeight );
			_b.show = Math.floor( _b.top + window.innerHeight/3 );
			_b.id 	= $(this).attr("id");
			blocks.push(_b);
		});
		$(".a").each(function(i){
			let _a = object(Animate);
			_a.top = Math.floor( $(this).offset().top );
			_a.show = Math.floor( _a.top + window.innerHeight/3 );
			if( lang === "en" && i === $(".a").length - 1){
				_a.show = _a.top;
			}
			animations.push(_a);
		});
		//console.log(bgs, blocks, animations)
		common.beforeW = winW;

		setTimeout(function(){
			if($body.hasClass("resize")){
				$body.removeClass("resize");
			}
		},300);
	},
	scrollToPosition: (posY, duration) =>{
		var $page 	= $("html,body"),
			ease 	= "easeInOutCubic",
			flag 	= true;
		$page.animate({
			scrollTop : posY + "px"
		},{
			"duration" 	: duration,
			"easing" 	: ease,
			"complete"	: function(){
				if(flag){
					flag = false;
				}
			}
		});
	},
	scrl: () =>{
		var topD = $(document).scrollTop(),
			btmD = topD + $(window).height();

		let blen = bgs.length;
		for( var b = 0; b < blen; b++ ){
			if(b === blen - 1){
				if(btmD >= bgs[b].top){
					activeBg(b);
				}
			}else{
				if(btmD >= bgs[b].top && btmD < bgs[b+1].top){
					activeBg(b);
				}
			}
		}
		let blockLen = blocks.length;
		for( var i = 0; i < blockLen; i++ ){
			if(i === (blockLen - 1) ){
				if(topD >= blocks[i].top){
					activeBlock(i);
				}
			}else{
				if(topD >= blocks[i].top && topD < blocks[i+1].top){
					activeBlock(i);
				}
			}
		}
		function activeBlock(i){
			let $blk = $(".block").eq(i);
			if( !$blk.hasClass("active") ){
				$(".block").removeClass("active");
				$blk.addClass("active");
				$("#glbnav li").find("a").removeClass("active");
				$("#glbnav li").eq(i).find("a").addClass("active");
			}
		}
		if(topD < blocks[0].top){
			if(	$(".block").eq(0).hasClass("active") ){
				$(".block").removeClass("active");
				$("#glbnav li a").removeClass("active");
			}
		}
		
		var d = btmD - bgs[common.currentBG].top;
		if(lang === "en"){
			if(common.currentBG != blen-1){
				var distance 	= (bgs[common.currentBG + 1].top) - bgs[common.currentBG].top; 
			}else{
				var distance 	= window.innerHeight;
			}
		}else{
			if(common.currentBG != blen-1){
				var distance 	= (bgs[common.currentBG + 1].top) - bgs[common.currentBG].top; 
			}else{
				var distance 	= $("#news").offset().top - bgs[common.currentBG].top;
			}
		}

		var distanceB = distance - window.innerHeight*1.5;
		if(lang === "en"){
			if(common.currentBG === blen-1){
				distanceB = distance - window.innerHeight*.5;
			}else if(common.currentBG === blen-2){
				distanceB = distance - window.innerHeight*2.5;
			}
		}
		var diffH 	= window.innerHeight*.1 * ( (distance - d)/distance);
		var diffB 	= window.innerHeight*.2 * ( (distanceB - d)/distanceB )/5;
		if(diffH < 0){ diffH = 0;}
		if(diffB < 0){ diffB = 0;}
		$('#bgs ul li').eq(common.currentBG).find("figure").css({
			"transform": "translateY(" + diffH + "px)"
		});

		if(common.currentBG > 0){
			$('#bgs ul li').eq(common.currentBG).find("figure").css({
				"filter": "blur(" + diffB + "px)"
			});
		}
		function activeBg(b){
			let $bgs = $("#bgs");
			if( !$bgs.hasClass("active" + b) ){
				common.currentBG = b;
				$bgs.removeClass();
				$bgs.addClass("active" + b);
			}
		}

		//animation
		let alen = animations.length;
		for( var a = 0; a < alen; a++ ){
			if(a === alen - 1){
				if(btmD >= animations[a].show){
					activeAnimate(a);
				}
			}else{
				if(btmD >= animations[a].show){
					activeAnimate(a);
				}
			}
		}
		function activeAnimate(a){
			let $a = $(".a").eq(a);
			if( !$a.hasClass("animate") ){
				$a.addClass("animate");
				setTimeout(function(){
					$a.addClass("activeAnimate");
				},600);
			}
		}
	},
	mouseCursor: function(){
		window.document.addEventListener("mousemove", getMousePos, false);
		function getMousePos(e){
			mouse[0] = e.clientX;
			mouse[1] = e.clientY;
		}
	}
}
document.addEventListener('DOMContentLoaded', function () {
	isPage = $("body").attr("id");
	lang = $html.lang;
	common.init();
});